import React from "react"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import styled from "styled-components"

import media from "components/media"
import rss from "images/rss.svg"

export const ChangelogContainer = styled.div`
  margin: 0 auto 2rem;
  height: 100%;
  padding: 0 3.2rem;
  max-width: 120rem;
  width: 100%;

  > div {
    margin: auto;
  }
`

export const Header = styled.div`
  display: flex;

  div.left {
    flex-shrink: 0;
    min-width: 12rem;
    width: 25%;
    margin-right: 2.4rem;
  }

  div.hero {
    padding: 8.6rem 0 4rem 1.4rem;

    h1 {
      font-size: 3.5rem;
    }

    p {
      font-size: 1rem;
      line-height: 2;
    }
  }

  div.subscribe {
    display: flex;
    align-items: center;
    a {
      margin-left: 5rem;
      line-height: initial;
    }
    img {
      height: 1.6rem;
      margin: 0 1rem 0 0;
    }
  }
`

export const ChangelogHeader = () => {
  return (
    <Header>
      <div className="left" />
      <div className="flex space-x-10 hero">
        <h1>Changelog</h1>
        <div className="flex my-auto align-center">
          <OutboundLink
            className="flex items-center btn btn-short btn-orange"
            href="/changelog/rss.xml"
          >
            <img src={rss} />
            <span className="text-lg">RSS Feed</span>
          </OutboundLink>
        </div>
      </div>
    </Header>
  )
}

export const Posts = styled.div`
  hr {
    border-color: grey;
  }
`

export const Post = styled.div`
  display: flex;
  ${media.sm.max`
    flex-direction: column;
  `}

  div.left {
    flex-shrink: 0;
    margin-right: 2.4rem;
    min-width: 12rem;
    width: 25%;

    h3 {
      padding-top: 3rem;
    }
    h4 {
      padding-top: 1rem;
    }
  }

  a {
    font-weight: 600;
    color: #3b82f6;
  }

  li {
    list-style: none;
  }

  div.post {
    // font-size: 1.25rem;
    // line-height: 3.3rem;
    margin: 2rem auto;
    // max-width: 800px;
    // width: 100%;
    // overflow-wrap: break-word;
    // text-rendering: optimizelegibility;

    div.gatsby-highlight > pre {
      border-radius: 1.2rem;
    }

    span.gatsby-resp-image-wrapper,
    figure.gatsby-resp-image-figure {
      margin: 4rem auto;
      span.gatsby-resp-image-wrapper {
        margin: initial;
      }
      span.gatsby-resp-image-background-image,
      img {
        border-radius: 1.2rem;
        max-width: 83rem;
      }
      .gatsby-resp-image-figcaption {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
`
