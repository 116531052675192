import React from "react"
import PropTypes from "prop-types"
import { Link, graphql } from "gatsby"

import SEO from "components/seo"
import OldStyle from "components/old-style"
import {
  ChangelogContainer,
  ChangelogHeader,
  Posts,
  Post,
} from "components/changelog"

export default function Changelog({ data }) {
  const posts = data.allMarkdownRemark.edges

  return (
    <OldStyle>
      <SEO
        title="Sourcery | Changelog - Updates and Improvements"
        description="Stay up to date on all of the changes and improvements to Sourcery - from IDE, to GitHub, to CLI."
      />
      <ChangelogContainer>
        <div>
          <ChangelogHeader />
          <Posts>
            {posts.map(({ node: post, index }) => (
              <div key={index}>
                <hr />
                <Post>
                  <div className="left">
                    <h3>
                      <Link
                        to={`/changelog/${post.frontmatter.date}-${post.fields.name}`}
                      >
                        {post.frontmatter.version ? (
                          <span>v{post.frontmatter.version}</span>
                        ) : (
                          post.frontmatter.prettydate
                        )}
                      </Link>
                    </h3>
                    {post.frontmatter.version ? (
                      <h4>{post.frontmatter.prettydate}</h4>
                    ) : null}
                  </div>
                  <div
                    className="post prose"
                    dangerouslySetInnerHTML={{ __html: post.html }}
                  />
                </Post>
              </div>
            ))}
          </Posts>
        </div>
      </ChangelogContainer>
    </OldStyle>
  )
}
Changelog.propTypes = {
  data: PropTypes.object.isRequired,
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { glob: "**/src/changelog/*/index.md" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            name
          }
          frontmatter {
            title
            version
            date(formatString: "YYYY-MM-DD")
            prettydate: date(formatString: "MMMM DD, YYYY")
          }
          html
        }
      }
    }
  }
`
