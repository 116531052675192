import styled from "styled-components"
import media from "components/media"

const OldStyle = styled.div`
  // color: ${(prop) => prop.theme.textColor};
  // font-family: Ubuntu light, sans-serif;

  font-size: 10px;
  ${media.lg.min`font-size: 10px;`}
  ${media.lg.max`font-size:  9px;`}
  ${media.md.max`font-size:  8px;`}
  ${media.sm.max`font-size:  7px;`}

  // background-color: ${(props) => props.theme.lightBackgroundColor};
  display: flex;
  flex-direction: column;
  ${media.md.max`
    padding: 0;
  `}

  * {
    box-sizing: border-box;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    // color: ${(props) => props.theme.titleColor};
    font-weight: 400;
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  h3 {
    font-size: 1.3rem;
  }

  h4 {
    font-size: 1rem;
  }

  h5 {
    font-size: 0.8rem;
  }

  h6 {
    font-size: 0.6rem;
  }

  a {
    // color: ${(props) => props.theme.linkColor};
    text-decoration: none;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  li {
    list-style: disc;
  }

  .tooltip {
    position: relative;
  }
  .tooltip:hover::before,
  .tooltip:hover::after {
    left: 50%;
    pointer-events: none;
    position: absolute;
    transform: translateX(-50%);
    z-index: 20;
  }
  .tooltip:hover::before {
    background-color: #ccc;
    border-radius: 0.3rem;
    bottom: 2.6rem;
    color: black;
    content: attr(aria-label);
    display: inline;
    font-size: 1.4rem;
    padding: 0.2rem 0.5rem;
    text-align: center;
    white-space: nowrap;
    width: max-content;
  }
  .tooltip:hover::after {
    border-color: #ccc transparent transparent transparent;
    border-style: solid;
    border-width: 1rem;
    bottom: 1rem;
    content: "";
  }
  .tooltip-middle:hover::before,
  .tooltip-middle:hover::after {
    bottom: 0;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    left: auto;
    z-index: 100;
    min-width: 160px;
    padding-top: 5px;
    padding-bottom: 5px;
    margin-top: 2px;
    list-style: none;
    border-radius: 4px;
    box-shadow: ${(props) => props.theme.shadow};
    background-color: ${(props) => props.theme.lightBackgroundColor};

    font-size: 1.6rem;
    li {
      list-style: none;
    }
  }
  .dropdown-menu::before {
    position: absolute;
    display: inline-block;
    content: "";
    top: -16px;
    right: 9px;
    left: auto;
  }
  .dropdown-header {
    padding: 0.8rem 1.5rem;
    margin-top: -1rem;
    border-bottom: solid 1px #e1e4e8;
    margin-bottom: 0.8rem;
  }

  // Ghost CSS Elements - layout debugging, https://stackoverflow.com/a/23482467/402441
  // * {
  //   background: #000 !important;
  //   color: #0f0 !important;
  //   outline: solid #f00 1px !important;
  // }
`
export default OldStyle
