import { css } from "styled-components"

const sizes = {
  // Extra Large
  xl: 1200,
  // Large
  lg: 992,
  // Medium
  md: 768,
  // Small
  sm: 662,
  // Extra Small
  xs: 576,
  // Tiny
  tn: 480,
  // Micro
  mc: 480,
}

// Iterate through the sizes and create a media template
const media = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = {
    max: (...args) => css`
      @media only screen and (max-width: ${sizes[label]}px) {
        ${css(...args)}
      }
    `,

    min: (...args) => css`
      @media only screen and (min-width: ${sizes[label] + 1}px) {
        ${css(...args)}
      }
    `,
  }

  return acc
}, {})

export default media
